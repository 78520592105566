<template>
  <div class="member-index">
    <el-row :gutter="10">
      <el-col :span="8">
        <el-card shadow="never" :body-style="{ padding: '0px' }">
          <div class="card-item">
            <div class="card-header">
              <svg-icon iconClass="coin" style="color: #f22020"></svg-icon>
              <span>硬币</span>
            </div>
            <div class="card-content">
              <p>
                <b>{{ total }}</b>个
              </p>
              <p>
                上传赚取:
                <span>{{ userinfo.upload }}</span>个，充值获取:
                <span>{{ userinfo.recharge }}</span> 个
              </p>
            </div>
            <div class="card-footer">
              <span class="c-p" v-pending>充值</span>
              <span class="c-p" v-pending>兑换</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never" :body-style="{ padding: '0px' }">
          <div class="card-item">
            <div class="card-header">
              <svg-icon iconClass="score" style="color: #f60"></svg-icon>
              <span>积分</span>
            </div>
            <div class="card-content">
              <p>
                <b>{{ userinfo.score }}</b>个
              </p>
            </div>
            <div class="card-footer">
              <span class="c-p" v-pending>赚取</span>
              <span class="c-p" v-pending>兑换</span>
              <span class="c-p" v-pending>商城</span>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never" :body-style="{ padding: '0px' }">
          <div class="card-item">
            <div class="card-header">
              <svg-icon iconClass="money-reward" style="color: #1296db"></svg-icon>
              <span>赏金</span>
            </div>
            <div class="card-content">
              <p>
                <b>{{ userinfo.moneyReward }}</b>个
              </p>
            </div>
            <div class="card-footer">
              <span class="c-p" v-pending>赚取</span>
              <span class="c-p" v-pending>提现</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-empty description="功能开发中~"></el-empty>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'
import SvgIcon from '../../components/SvgIcon.vue'

export default {
  components: { SvgIcon },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['userinfo']),
    total() {
      return this.userinfo.upload + this.userinfo.recharge
    }
  }
}
</script>

<style lang="scss" scoped>
.card-item {
  display: flex;
  // height: 200px;
  flex-direction: column;
  .card-header {
    padding: 20px 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .svg-icon {
      font-size: 36px;
    }
    span {
      margin-top: 6px;
      color: #666;
      font-size: 14px;
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    p {
      color: #777;
      margin-bottom: 10px;
    }
    b {
      font-size: 22px;
      color: #444;
    }
    span {
      font-size: 18px;
      color: #444;
    }
  }
  .card-footer {
    height: 42px;
    border-top: 1px solid #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    span {
      flex: 1;
      text-align: center;
      color: #666;
      font-size: 14px;
    }
  }
}
.c-p {
  cursor: pointer;
}
</style>